import React from "react"
//import { Link } from "gatsby"
import { Text, View, StyleSheet } from "react-native-web"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Geometry from "../components/geometry"

const IndexPage = () => (
  <Layout>
    <SEO title="Frapper" />
    <View style={styles.container}>
      <View style={StyleSheet.absoluteFillObject}>
        <Geometry />
      </View>
      <View style={styles.box}>
        <Text style={styles.title}>Frapper</Text>
        <Text style={styles.contact}><Text accessibilityRole="link" href="mailto:filip@frapper.se">filip@frapper.se</Text> | <Text style={styles.contact} accessibilityRole="link" href="tel:+46 (0)734 33 90 09">+46 (0)734 33 90 09</Text></Text>
      </View>
    </View>
  </Layout>
)

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundImage: "linear-gradient(to bottom, #304365 0%, #1A2941 100%)",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontFamily: '"Roboto", sans-serif',
    fontWeight: "600",
    fontSize: 46,
    color: "#304365",
    textTransform: "uppercase",
  },
  contact: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: 14,
    color: "#304365",
  },
  box: {
    padding: 10,
  },
})

export default IndexPage
