import React, { useEffect, useRef } from "react"
import { Dimensions } from "react-native-web"

const CELL_WIDTH = 56
const CELL_HEIGHT = 50
const REAL_CELL_HEIGHT = 66

const Geometry = () => {
  const root = useRef()
  useEffect(() => {
    setTimeout(() => {
      root.current.childNodes.forEach((path, index) => {
        setTimeout(() => {
          path.style.opacity = null
        }, Math.random() * index * 0.5 * 30)
      })
    }, 500)
  })
  const honeyComb = []

  const { height, width } = Dimensions.get("window")

  let hCount = width / CELL_WIDTH
  let vCount = height / CELL_HEIGHT
  hCount = Math.ceil(hCount)
  vCount = Math.ceil(vCount)

  const textRect = { width: 253, height: 90 }
  textRect.left = width / 2 - textRect.width / 2
  textRect.right = width / 2 + textRect.width / 2
  textRect.top = height / 2 - textRect.height / 2
  textRect.bottom = height / 2 + textRect.height / 2
  let hStep
  let vStep
  for (hStep = 0; hStep <= hCount; hStep++) {
    for (vStep = 0; vStep <= vCount; vStep++) {
      const x = hStep * CELL_WIDTH - (vStep % 2) * 28
      const y = vStep * CELL_HEIGHT

      const fill =
        x + CELL_WIDTH / 2 > textRect.left &&
        x - CELL_WIDTH / 2 < textRect.right &&
        y + REAL_CELL_HEIGHT > textRect.top &&
        y < textRect.bottom

      honeyComb.push(
        <path
          key={`${hStep}:${vStep}`}
          d={`M ${x} ${y}l -28 16v 34l 28 16l 28 -16v -34z`}
          fill={fill ? "#fff" : "none"}
          stroke="#fff"
          strokeWidth="2"
          style={{ opacity: 0, transition: "opacity 0.3s" }}
        />
      )
    }
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      ref={root}
    >
      {honeyComb}
    </svg>
  )
}

export default Geometry
